var site = site || {};

(function ($) {
  /**
   * Basic sub-block toggle for gnav utility templates
   * inside this formatter
   * sub-templates need to have a trigger with class .js-gnav-util-trigger
   * the following div should have .js-gnav-util__content .hidden
   *
   * NOTE: the cart utility link manages its' own toggle so we can render
   * cart content via js when opening
   * ~/smashbox_base/template_api/navigation/gnav_cart_v1/gnav_cart_v1.js
   *
   */
  Drupal.behaviors.utilityNavFormatter = {
    attach: function (context) {
      var $nav = $('.js-nav--utility', context);
      var $navHeaderMenu = $('.js-nav--primary', context);
      var $block = $('.gnav-util-formatter__item', context);
      var $trigger = $('.js-gnav-util-trigger', $block);
      var $menuTrigger = $('.js-gnav-mobile-trigger', $block);
      var $closeTrigger = $('.js-gnav-util-close', $block);
      var $blockContent = $('.js-gnav-util__content', $block);
      var $body = $('body', context);
      var $contentDim = $body.find('.js-content-dim');
      var $siteHeader = $('.site-header', context);
      var $siteHeaderNav = $('.site-header__section--nav', context);
      var $mobileBackButton = $('.js-mobile-checkout-back', context);
      var mobileCheckout = !!$('body.checkout, body.viewcart').length;
      var $triggerCartItem = $('.js-gnav-util-trigger--cart', $block);
      var isMobileBackPage = !!$('body.checkout-opc, body .checkout.checkout--confirmation-page').length;
      var isMobileOpcSigninPage = !!$('body.checkout-opc .checkout-panel--sign-in-main.active').length;
      var $mobileBack = $('.js-mobile-back', context);
      var $cartFirstElement = $('.js-cart-block-container:visible').find('a:first');
      var gnavTabRetainFocus = '';
      var $accountblock = $('.js-gnav-util__account', $block);

      $accountblock.closest($block).addClass('gnav-util__account-wrapper');

      if (mobileCheckout) {
        if (!$('.checkout--confirmation-page').length) {
          $mobileBackButton.appendTo('.site-header .gnav-util-formatter');
          $mobileBackButton.removeClass('hidden');
        } else if ($('.checkout--confirmation-page').length) {
          $mobileBackButton.remove();
        }
      } else {
        $mobileBackButton.remove();
      }

      if (isMobileBackPage && !isMobileOpcSigninPage) {
        $mobileBack.removeClass('hidden').addClass('show-opt-only'); // no need to check mobile for this as its inside a pc-hidden div
        $menuTrigger.addClass('opt-hidden');
      }

      function close() {
        $blockContent.addClass('hidden');
        $trigger.add($triggerCartItem).removeClass('active');
        $(this).next('.js-gnav-util__content').removeClass('hidden');

        // Once other events have triggered, check if the menu link was clicked
        // If it was, we want to keep the black theme
        setTimeout(function () {
          if (!$('.site-header__main .menu__item.active', context).length) {
            $body.removeClass('gnav-active');
          }
        }, 100);
      }

      function navDimensions() {
        var offset = $siteHeader.outerHeight(true);
        var height = $(window).height() - offset;

        $siteHeaderNav.css({
          top: offset,
          height: height
        });
      }

      $(window).on(
        'resize',
        _.debounce(function () {
          if ($body.hasClass('mobile-gnav-active')) {
            navDimensions();
          }
        }, 250)
      );

      // $nav.on('clickoutside', function() {
      //   $blockContent.addClass('hidden');
      //   close();
      // });

      $trigger.once().on('click keydown', function (event) {
        // If the menu was previously open, immediately remove its active classes
        // We're doing this instead of click because the gnav waits for transitions to end before removing classes
        // if ($('.site-header__main .menu__item.active', context).length) {
        //   $('.site-header__main .menu__item.active', context).removeClass('active menu__item--expanded');
        // }

        // subnav: next > js-gnav-util__content
        if (event.which === 13 || event.which === 1) {
          event.preventDefault();
          gnavTabRetainFocus = event.currentTarget;
          var $subnav = $(this).next('.js-gnav-util__content');

          site.globalNavigation.route($subnav, 'utility');

          $body.removeClass('mobile-gnav-active');

          // var $thisContent = $(this).next('.js-gnav-util__content');
          // $blockContent.not($thisContent).addClass('hidden');
          // $trigger.add($triggerCartItem).removeClass('active');
          // $(this).toggleClass('active', $thisContent.hasClass('hidden'));
          // $thisContent.toggleClass('hidden');

          $trigger.removeClass('active');
          $(this).addClass('active');
          $(this).attr('aria-expanded', 'true');
          // We want to check if we're just switching to another utility nav item
          // If we are we want to keep the gnav-active on the body
          // $body.toggleClass('gnav-active', !$thisContent.hasClass('hidden'));

          // focus search input if search icon clicked
          if ($subnav.hasClass('gnav-util__content--search')) {
            $navHeaderMenu.addClass('header-inactive');
            var $searchForm = $subnav.find('.js-end-esearch-nav__form');
            var $searchInput = $searchForm.find('.js-end-search-term');

            $searchInput.blur().val('').focus();
          }

          if ($subnav.hasClass('js-cart-block-container')) {
            $cartFirstElement.addClass('custom-outline').focus();
          }

          $(this).trigger('click').attr('aria-expanded', 'true');
          site.restrict_navigation($subnav);
        }
      });

      $cartFirstElement.focusout(function () {
        $(this).removeClass('custom-outline');
      });

      $('.js-gnav-util-trigger--cart').click(function () {
        $('.js-cart-block-container').focus();
      });
      // hamburger adds a body class so we can toggle main nav
      $menuTrigger.once().on('click', function (event) {
        event.preventDefault();
        // site.globalNavigation.closeSubNav(0);
        $body.toggleClass('mobile-gnav-active');
        navDimensions();

        site.globalNavigation.closeSubNav(0);
        $(document).trigger('gnav_hamburger_click');
      });

      // content block close
      // js-gnav-util-close
      $closeTrigger
        .once()
        .on('click', function (event) {
          event.preventDefault();
          Drupal.behaviors.gnavCart.setState();
          $navHeaderMenu.removeClass('header-inactive');
          site.globalNavigation.closeSubNav(0);
          $(gnavTabRetainFocus).attr('aria-expanded', 'false').focus();
        })
        .on({
          // ADA Compliance for Keyboard Event
          keydown: function (e) {
            if (site.getKeycode(e) === 13) {
              e.preventDefault();
              $(this).trigger('click');
            }
          }
        });

      $(document).on('close_utility', function (event) {
        site.globalNavigation.closeSubNav(0);
      });

      $blockContent.on('mouseleave', function () {
        // Brand requested to only close the account gnav on click elsewhere.
        if ($(this).hasClass('gnav-util__content')) {
          return;
        }
        site.globalNavigation.closeSubNav(0);
      });

      // content block close when overlay is clicked
      // .gnav-active .js-content-dim
      // $contentDim.once().on('click', function() {
      //  close();
      // });
    }
  };
})(jQuery);
